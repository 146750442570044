table th{
    width: 250px;
  }
  .billhead1{
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .total{
    text-align: right;
  }
  .head2{
    display: flex;
    align-items:flex-end;
    justify-content: left;
    }
  .footer{
    margin-top: 100px;
  }
  
  .td{
    width: 300px;
  }
  table td{
    width: 200px;
  }
  .table{
    height: 200px;
  }
  .last{
    text-align: right;
  }
  .note{
    display: flex;
    flex-direction: column;
  align-items: center;
  justify-content: center;
  }
  .right{
    text-align: right;
  }
  .imagepara{
    display: flex;
  }
  .imagepara img{
    margin-left: -13px;
    margin-right: 10px;
    height: 60px;
    
  }
  .input{
    width: 40px;
  }
  .data{
    width:250px;
  }