
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("./images/LogoBG31Blur.png"); */
  background-attachment: fixed;
  background-color: white;
  top: 0;
  right: 0;
  width: 100%;
  
  
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(155, 154, 154);
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-container {
  max-height: 20rem; /* Adjust as needed */
  overflow-y: auto;
  overflow-x: hidden;
}

.table {
  width: 100%;
 
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* Background color of the header */
  z-index: 1;
}

.table th,
.table td {
  padding: 8px 12px;

}

.table th {
  text-align: left;
  /* background-color: #f8f8f8; Background color of the header */
}

.custom-navbar{
  background-image: linear-gradient(rgb(206, 206, 206), white);
  /* color: #ffffff; */
  /* box-shadow: 10px 0px 20px 10px rgb(224, 223, 223); */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  font-family: "Fredoka", sans-serif;
}

.custom-navlink{
  color: rgb(0, 0, 0);
  background-color: white;

}
.custom-navlink:hover{
  color: rgb(183, 11, 11);
}

.dashboard-btn{
background-color:#e3373d;
color: #ffffff;
animation: leave 0.4s forwards;
&:hover{
  background-color:#d57174;
/* color: rgb(0, 0, 0); */
animation: hover 0.4s forwards;
}
}

@keyframes hover {
  from {
      box-shadow: inset 0 0 0 0.01px #2faee4;
  }
  to {
      box-shadow: inset 8.79928em 0 0 0.01px #903b73;
      color: #fff;
  }
}

@keyframes leave {
  from {
      box-shadow: inset -8.79928em 0 0 0.01px #2faee4;
      color: #fff;
  }
  to {
      box-shadow: inset 0 0 0 0.01px #903b73;
  }
}

.custom-div{
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.popup-div{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(2px);

}



.custom-popup-div{
 z-index: 100;
  position: fixed;
}

.custom-card{
  background-color: white;
  border-radius: 10px;
  border: none;
  font-family: "Poppins", sans-serif;  font-weight: 500;
  /* border-color: black; */
  /* box-shadow: rgba(0, 0, 0, 0.30) 2px 5px 10px; */
}
.cssbuttons-io-button {
  background: #852564;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em 
  #e21f24;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
}

.cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em  #852564;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #852564
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}

.custom-button{
  
  width: 100px;
  word-wrap: break-word;
  background-color: #009ee3;
  color: white;
  margin-right: 20px;
  border-radius: 5px;
  border: 2px solid black;

}


.custom-button:hover{
  background-color: white;
  color: black;
  border: 2px solid black;
}
.error{
  color: red;
}

.custom-btn{
  
  max-width: 100%;
  word-wrap: break-word;
  background-color: #009ee3;
  color: white;
  
  border-radius: 5px;

}


.table-row{
  background-color: #e21f24;
 
}

.product-custom-div{
  background-color: #fff;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 20px;

}

.category-custom-div{
  background-color: rgb(243, 243, 243);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


@media (min-width: 757px) {
  .min-height-70vh {
    min-height: 70vh;
  }
}

@media (max-width: 757px) {
  .min-height-70vh {
    min-height: 20vh;
  }
}